var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(" 尊敬的 "),_c('span',{staticClass:"name underline"},[_vm._v(_vm._s(_vm.studentInfo.name))]),_vm._v(" 家长： ")]),_c('p',{staticClass:"indent"},[_vm._v(" 感谢您参与香港理工大学中文及双语系盛欐教授与中南大学湘雅二医院康复医学科王如蜜副主任开展的汉语儿童语言障碍研究项目。在本次研究中，我们对 "),_c('span',{staticClass:"name underline"},[_vm._v(_vm._s(_vm.studentInfo.name))]),_vm._v(" 进行了以下几项测试，旨在全面评估儿童的听力、智力及语言能力： ")]),_vm._m(1),_c('p',{staticClass:"indent"},[_vm._v(" 此后，三方经验丰富的言语语言治疗师分别对 "),_c('span',{staticClass:"name underline"},[_vm._v(_vm._s(_vm.studentInfo.name))]),_vm._v(" 的语言测试表现进行了综合诊断。根据结果，我们将儿童分为以下三组： ")]),_vm._m(2),_c('p',[_c('span',{staticClass:"name underline"},[_vm._v(_vm._s(_vm.studentInfo.name))]),_vm._v(" 在本次研究中的测试表现及言语治疗师诊断结果如下： ")]),_c('div',{staticClass:"table"},[_c('table',[_vm._m(3),_c('tbody',[_vm._m(4),_c('tr',[_c('td',[_vm._v("智力测试")]),_c('td',[_vm._v(" 百分位数"+_vm._s(_vm.studentInfo.score1)+"%(参与测试的"+_vm._s(_vm.studentInfo.score1)+"%儿童表现低于贵子女) ")])]),_c('tr',[_c('td',[_vm._v("普通话句子重复任务")]),_c('td',[_vm._v(" 百分位数"+_vm._s(_vm.studentInfo.score2)+"%(参与测试的"+_vm._s(_vm.studentInfo.score2)+"%儿童表现低于贵子女) ")])]),_c('tr',[_c('td',[_vm._v("言语治疗师语言综合诊断")]),_c('td',[_vm._v(" "+_vm._s(_vm.studentInfo.result)+" ")])])])])]),_vm._m(5),_c('p',{staticClass:"indent"},[_vm._v("祝您的孩子健康、快乐成长！再次感谢您的参与和支持！")])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"img img1"},[_c('img',{attrs:{"src":require("../../assets/header1.png"),"alt":""}})]),_c('div',{staticClass:"img img2"},[_c('img',{attrs:{"src":require("../../assets/header2.png"),"alt":""}})]),_c('div',{staticClass:"img img3"},[_c('img',{attrs:{"src":require("../../assets/header3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"indent"},[_c('li',[_vm._v(" 1. "),_c('span',{staticClass:"bold"},[_vm._v("听力测试：")]),_vm._v("使用专业测听程序进行听力测试，判断儿童的听力是否正常。 ")]),_c('li',[_vm._v(" 2. "),_c('span',{staticClass:"bold"},[_vm._v("智力测试：")]),_vm._v("使用图形推理任务评估儿童非语言智力水平。 ")]),_c('li',[_vm._v(" 3. "),_c('span',{staticClass:"bold"},[_vm._v("普通话句子重复任务：")]),_vm._v("儿童需要听到一个句子后准确复述内容，通过句子重复任务，快速评估儿童的语言理能力。 ")]),_c('li',[_vm._v(" 4. "),_c('span',{staticClass:"bold"},[_vm._v("全面语言测试：")]),_vm._v("涵盖一系列语言理解（听指令、词汇类别等）和语言表达（词语定义、造句等）相关的任务，深入评估儿童在词汇、句子、篇章层次的语言能力。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"indent"},[_c('li',[_c('span',{staticClass:"bold"},[_vm._v("语言障碍：")]),_vm._v("三方治疗师均判定儿童为语言障碍； ")]),_c('li',[_c('span',{staticClass:"bold"},[_vm._v("语言障碍风险：")]),_vm._v("两方治疗师判定儿童为语言障碍； ")]),_c('li',[_c('span',{staticClass:"bold"},[_vm._v("正常发展：")]),_vm._v("仅一方或没有治疗师判定儿童为语言障碍； ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("项目")]),_c('th',[_vm._v("结果")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("听力测试")]),_c('td',[_vm._v("听力正常")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"indent"},[_vm._v(" 我们希望通过科学严谨的评估，为每位儿童的语言发展状况提供指导依据。本次测试的结果将为儿童语言发展的个性化建议提供重要参考。如果您对本次测试过程或评估结果有任何疑问，欢迎随时与我们联系（香港理工大学： "),_c('a',{attrs:{"href":"mailto:hkpullblab@gmail.com"}},[_vm._v("hkpullblab@gmail.com")]),_vm._v(" ；湘雅二医院： "),_c('a',{attrs:{"href":"mailto:tanyuallen@163.com"}},[_vm._v("tanyuallen@163.com")]),_vm._v(" ）。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('p',[_vm._v("香港理工大学【汉语儿童语言障碍研究】课题组")]),_c('p',[_vm._v("中南大学湘雅二医院康复医学科")]),_c('p',[_vm._v("2025年1月")])])
}]

export { render, staticRenderFns }