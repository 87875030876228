<template>
  <div class="wrapper">
    <div class="header">
      <div class="img img1">
        <img src="../../assets/header1.png" alt="" />
      </div>
      <div class="img img2">
        <img src="../../assets/header2.png" alt="" />
      </div>
      <div class="img img3">
        <img src="../../assets/header3.png" alt="" />
      </div>
    </div>
    <div class="content">
      <p class="title">
        尊敬的
        <span class="name underline">{{ studentInfo.name }}</span>
        家长：
      </p>
      <p class="indent">
        感谢您参与香港理工大学中文及双语系盛欐教授与中南大学湘雅二医院康复医学科王如蜜副主任开展的汉语儿童语言障碍研究项目。在本次研究中，我们对
        <span class="name underline">{{ studentInfo.name }}</span>
        进行了以下几项测试，旨在全面评估儿童的听力、智力及语言能力：
      </p>
      <ul class="indent">
        <li>
          1.
          <span class="bold">听力测试：</span
          >使用专业测听程序进行听力测试，判断儿童的听力是否正常。
        </li>
        <li>
          2.
          <span class="bold">智力测试：</span
          >使用图形推理任务评估儿童非语言智力水平。
        </li>
        <li>
          3.
          <span class="bold">普通话句子重复任务：</span
          >儿童需要听到一个句子后准确复述内容，通过句子重复任务，快速评估儿童的语言理能力。
        </li>
        <li>
          4.
          <span class="bold">全面语言测试：</span
          >涵盖一系列语言理解（听指令、词汇类别等）和语言表达（词语定义、造句等）相关的任务，深入评估儿童在词汇、句子、篇章层次的语言能力。
        </li>
      </ul>

      <p class="indent">
        此后，三方经验丰富的言语语言治疗师分别对
        <span class="name underline">{{ studentInfo.name }}</span>
        的语言测试表现进行了综合诊断。根据结果，我们将儿童分为以下三组：
      </p>
      <ul class="indent">
        <li>
          <span class="bold">语言障碍：</span>三方治疗师均判定儿童为语言障碍；
        </li>
        <li>
          <span class="bold">语言障碍风险：</span>两方治疗师判定儿童为语言障碍；
        </li>
        <li>
          <span class="bold">正常发展：</span
          >仅一方或没有治疗师判定儿童为语言障碍；
        </li>
      </ul>

      <p>
        <span class="name underline">{{ studentInfo.name }}</span>
        在本次研究中的测试表现及言语治疗师诊断结果如下：
      </p>

      <div class="table">
        <table>
          <!-- 表头部分 -->
          <thead>
            <tr>
              <th>项目</th>
              <th>结果</th>
            </tr>
          </thead>

          <!-- 表体部分 -->
          <tbody>
            <tr>
              <td>听力测试</td>
              <td>听力正常</td>
            </tr>
            <tr>
              <td>智力测试</td>
              <td>
                百分位数{{ studentInfo.score1 }}%(参与测试的{{
                  studentInfo.score1
                }}%儿童表现低于贵子女)
              </td>
            </tr>
            <tr>
              <td>普通话句子重复任务</td>
              <td>
                百分位数{{ studentInfo.score2 }}%(参与测试的{{
                  studentInfo.score2
                }}%儿童表现低于贵子女)
              </td>
            </tr>
            <tr>
              <td>言语治疗师语言综合诊断</td>
              <td>
                {{ studentInfo.result }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="indent">
        我们希望通过科学严谨的评估，为每位儿童的语言发展状况提供指导依据。本次测试的结果将为儿童语言发展的个性化建议提供重要参考。如果您对本次测试过程或评估结果有任何疑问，欢迎随时与我们联系（香港理工大学：
        <a href="mailto:hkpullblab@gmail.com">hkpullblab@gmail.com</a>
        ；湘雅二医院：
        <a href="mailto:tanyuallen@163.com">tanyuallen@163.com</a>
        ）。
      </p>

      <p class="indent">祝您的孩子健康、快乐成长！再次感谢您的参与和支持！</p>
    </div>
    <div class="footer">
      <p>香港理工大学【汉语儿童语言障碍研究】课题组</p>
      <p>中南大学湘雅二医院康复医学科</p>
      <p>2025年1月</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "V2",
  props: ["studentInfo"],
  computed: {
    percent() {
      return this.studentInfo?.Z_INFO?.percent || [];
    },
  },
};
</script>

<style scoped lang="less">
.underline {
  position: relative;
  display: inline-block;
  text-indent: 0;
}
.underline:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; /* 调整下划线与文本底部的距离 */
  border-bottom: 1px solid black; /* 下划线样式 */
}
.indent {
  text-indent: 4ch;
}
.bold {
  font-weight: bold;
}
.wrapper {
  line-height: 2;
  background: white;
  min-width: 600px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .img {
      &.img1 {
        width: 200px;
      }
      &.img2 {
        width: 100px;
      }
      &.img3 {
        width: 200px;
      }
      img {
        width: 100%;
      }
    }
  }
  .content {
    a {
      text-decoration: underline;
    }
    .name {
      padding: 0 6px;
    }
    .title {
      .name {
        font-weight: bolder;
      }
    }

    .table {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0;

      table {
        width: 90%;
        border-collapse: collapse;
        border: 1px solid #ccc;
        text-align: center;
        thead {
          background: #a12337;
          color: white;
        }
        th {
          border: 1px solid black;
        }
        td {
          border: 1px solid black;
        }
      }
    }
  }
  .footer {
    text-align: right;
  }
}
</style>
