<template>
  <div class="login">
    <h2>报告查询</h2>
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        name="school"
        :value="form.school"
        label="所属学校"
        placeholder="点击选择学校"
        :rules="[{ required: true, message: '孩子学校为必填项' }]"
        @click="onSchoolFieldClick"
      />
      <van-field
        v-model="form.name"
        name="name"
        label="孩子姓名"
        placeholder="孩子姓名"
        clearable
        :rules="[{ required: true, message: '孩子姓名为必填项' }]"
      />
      <van-field
        readonly
        clickable
        name="birthday"
        :value="form.birthday"
        label="孩子生日"
        placeholder="点击选择生日"
        :rules="[{ required: true, message: '孩子生日为必填项' }]"
        @click="onDateFieldClick"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="dateValue"
          type="date"
          @confirm="onConfirmDate"
          @cancel="showPicker = false"
          :minDate="minDate"
          :maxDate="maxDate"
        />
      </van-popup>
      <van-popup v-model="showSchoolPicker" position="bottom">
        <van-picker
          title="选择学校"
          show-toolbar
          :columns="schools"
          @confirm="onConfirmSchool"
          @cancel="showSchoolPicker = false"
        />
      </van-popup>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          立即查看
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import all from "@/utils/constants/students";
import dayjs from "dayjs";
const defaultDate = new Date(2018, 0, 1);
export default {
  name: "Login2",
  data() {
    return {
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      showPicker: false,
      dateValue: defaultDate,
      showSchoolPicker: false,
      schoolValue: "",
      form: {
        school: "",
        name: "",
        birthday: "",
      },
    };
  },
  computed: {
    schools() {
      return all.schools;
    },
  },
  methods: {
    onDateFieldClick() {
      this.showPicker = true;
      this.dateValue = this.form.birthday
        ? new Date(this.form.birthday)
        : defaultDate;
    },
    onConfirmDate(date) {
      this.form.birthday = dayjs(date).format("YYYY-MM-DD");
      this.showPicker = false;
    },
    onSchoolFieldClick() {
      this.showSchoolPicker = true;
      this.schoolValue = this.form.school || "";
    },
    onConfirmSchool(value) {
      this.form.school = value;
      this.showSchoolPicker = false;
    },
    onSubmit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  padding: 16px;
  margin: 100px auto;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>