const data =  {
  "schools": [
    "小白鹤幼儿园",
    "璀璨天城幼儿园",
    "中建璟和城幼儿园",
    "明昇第二幼儿园",
    "砂子塘魅力小学",
    "长塘里思远小学",
    "泰禹第二小学",
    "长郡雨花外国语学校",
    "育新第三小学"
  ],
  "data": [
    {
      "school": "小白鹤幼儿园",
      "school_pinyin": "xiaobaiheyoueryuan",
      "name": "李弦泽",
      "name_pinyin": "lixianze",
      "birthday": "2017-07-24",
      "score1": "96.0",
      "score2": "3.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "小白鹤幼儿园",
      "school_pinyin": "xiaobaiheyoueryuan",
      "name": "邓悦琳",
      "name_pinyin": "dengyuelin",
      "birthday": "2016-06-05",
      "score1": "30.0",
      "score2": "1.0",
      "result": "发展性语言障碍"
    },
    {
      "school": "璀璨天城幼儿园",
      "school_pinyin": "cuicantianchengyoueryuan",
      "name": "王晨博",
      "name_pinyin": "wangchenbo",
      "birthday": "2018-04-26",
      "score1": "70.0",
      "score2": "8.7",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "贺飞扬",
      "name_pinyin": "hefeiyang",
      "birthday": "2017-09-26",
      "score1": "30.0",
      "score2": "13.1",
      "result": "发展性语言障碍"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "李辰漾",
      "name_pinyin": "lichenyang",
      "birthday": "2018-01-22",
      "score1": "99.0",
      "score2": "26.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "李星辰",
      "name_pinyin": "lixingchen",
      "birthday": "2018-02-11",
      "score1": "7.0",
      "score2": "62.9",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "刘钰彤",
      "name_pinyin": "liuyutong",
      "birthday": "2018-06-11",
      "score1": "84.0",
      "score2": "62.9",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "彭梓俊",
      "name_pinyin": "pengzijun",
      "birthday": "2017-12-04",
      "score1": "68.0",
      "score2": "26.2",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "尹书妍",
      "name_pinyin": "yinshuyan",
      "birthday": "2018-04-18",
      "score1": "14.0",
      "score2": "8.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "张雪萌",
      "name_pinyin": "zhangxuemeng",
      "birthday": "2018-01-25",
      "score1": "47.0",
      "score2": "62.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "梁瑾瑜",
      "name_pinyin": "liangjinyu",
      "birthday": "2018-11-16",
      "score1": "39.0",
      "score2": "53.5",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "廖泓博",
      "name_pinyin": "liaohongbo",
      "birthday": "2017-09-28",
      "score1": "23.0",
      "score2": "71.6",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "许展翎",
      "name_pinyin": "xuzhanling",
      "birthday": "2018-01-02",
      "score1": "32.0",
      "score2": "79.1",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "尹景乔",
      "name_pinyin": "yinjingqiao",
      "birthday": "2018-06-03",
      "score1": "96.0",
      "score2": "8.7",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "张清语",
      "name_pinyin": "zhangqingyu",
      "birthday": "2018-08-05",
      "score1": "99.0",
      "score2": "43.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "蔡眸宣",
      "name_pinyin": "caimouxuan",
      "birthday": "2018-03-12",
      "score1": "32.0",
      "score2": "13.1",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "王博涵",
      "name_pinyin": "wangbohan",
      "birthday": "2018-01-04",
      "score1": "39.0",
      "score2": "1.0",
      "result": "发展性语言障碍"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "戴以轩",
      "name_pinyin": "daiyixuan",
      "birthday": "2018-05-26",
      "score1": "91.0",
      "score2": "13.1",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "彭诗瑜",
      "name_pinyin": "pengshiyu",
      "birthday": "2018-06-09",
      "score1": "94.0",
      "score2": "71.6",
      "result": "正常发展"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "向家铃",
      "name_pinyin": "xiangjialing",
      "birthday": "2018-04-06",
      "score1": "47.0",
      "score2": "8.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "中建璟和城幼儿园",
      "school_pinyin": "zhongjianjinghechengyoueryuan",
      "name": "张书杭",
      "name_pinyin": "zhangshuhang",
      "birthday": "2018-08-18",
      "score1": "50.0",
      "score2": "5.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "何沐宛",
      "name_pinyin": "hemuwan",
      "birthday": "2017-12-05",
      "score1": "39.0",
      "score2": "19.0",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "宁悦彤",
      "name_pinyin": "ningyuetong",
      "birthday": "2018-04-24",
      "score1": "30.0",
      "score2": "71.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "王楠",
      "name_pinyin": "wangnan",
      "birthday": "2017-09-24",
      "score1": "88.0",
      "score2": "71.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "张梓煜",
      "name_pinyin": "zhangziyu",
      "birthday": "2018-03-10",
      "score1": "87.0",
      "score2": "71.6",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "刘欣羽",
      "name_pinyin": "liuxinyu",
      "birthday": "2018-06-19",
      "score1": "63.0",
      "score2": "5.4",
      "result": "正常发展"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "罗恩泽",
      "name_pinyin": "luoenze",
      "birthday": "2018-08-11",
      "score1": "79.0",
      "score2": "1.0",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "张佑豪",
      "name_pinyin": "zhangyouhao",
      "birthday": "2018-04-24",
      "score1": "47.0",
      "score2": "62.9",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "邹沐真",
      "name_pinyin": "zoumuzhen",
      "birthday": "2018-02-19",
      "score1": "14.0",
      "score2": "71.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "彭予容",
      "name_pinyin": "pengyurong",
      "birthday": "2017-10-05",
      "score1": "3.0",
      "score2": "19.0",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "王语晨",
      "name_pinyin": "wangyuchen",
      "birthday": "2017-09-21",
      "score1": "73.0",
      "score2": "71.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "吴诗墨",
      "name_pinyin": "wushimo",
      "birthday": "2018-04-26",
      "score1": "18.0",
      "score2": "19.0",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "明昇第二幼儿园",
      "school_pinyin": "mingshengdieryoueryuan",
      "name": "吴足毅",
      "name_pinyin": "wuzuyi",
      "birthday": "2018-07-15",
      "score1": "91.0",
      "score2": "62.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李佳芯",
      "name_pinyin": "lijiaxin",
      "birthday": "2016-12-20",
      "score1": "68.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "卜萝娜",
      "name_pinyin": "buluona",
      "birthday": "2016-09-14",
      "score1": "90.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "黄宸逸",
      "name_pinyin": "huangchenyi",
      "birthday": "2016-10-09",
      "score1": "79.0",
      "score2": "61.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "吴语桐",
      "name_pinyin": "wuyutong",
      "birthday": "2016-11-05",
      "score1": "81.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "杨文暄",
      "name_pinyin": "yangwenxuan",
      "birthday": "2016-11-16",
      "score1": "84.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "陈思宇",
      "name_pinyin": "chensiyu",
      "birthday": "2013-01-04",
      "score1": "95.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李俊锋",
      "name_pinyin": "lijunfeng",
      "birthday": "2016-09-24",
      "score1": "87.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李欣悦",
      "name_pinyin": "lixinyue",
      "birthday": "2017-02-07",
      "score1": "27.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "刘咏田",
      "name_pinyin": "liuyongtian",
      "birthday": "2017-02-23",
      "score1": "98.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "刘煜欣",
      "name_pinyin": "liuyuxin",
      "birthday": "2017-02-27",
      "score1": "94.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "涂景萱",
      "name_pinyin": "tujingxuan",
      "birthday": "2017-08-05",
      "score1": "88.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "钟章承",
      "name_pinyin": "zhongzhangcheng",
      "birthday": "2017-01-03",
      "score1": "7.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "彭涵瑜",
      "name_pinyin": "penghanyu",
      "birthday": "2017-08-10",
      "score1": "7.0",
      "score2": "4.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "汪楷霖",
      "name_pinyin": "wangkailin",
      "birthday": "2017-06-04",
      "score1": "99.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "刘清让",
      "name_pinyin": "liuqingrang",
      "birthday": "2016-10-29",
      "score1": "70.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "柳宏朗",
      "name_pinyin": "liuhonglang",
      "birthday": "2016-11-12",
      "score1": "98.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李成昊",
      "name_pinyin": "lichenghao",
      "birthday": "2016-10-29",
      "score1": "70.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "刘梓沐",
      "name_pinyin": "liuzimu",
      "birthday": "2017-01-23",
      "score1": "39.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "孙嘉言",
      "name_pinyin": "sunjiayan",
      "birthday": "2016-06-15",
      "score1": "50.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "杨子涛",
      "name_pinyin": "yangzitao",
      "birthday": "2017-08-04",
      "score1": "66.0",
      "score2": "4.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "张雨兮",
      "name_pinyin": "zhangyuxi",
      "birthday": "2016-11-22",
      "score1": "75.0",
      "score2": "1.4",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "何新珹",
      "name_pinyin": "hexincheng",
      "birthday": "2017-02-07",
      "score1": "10.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "谢嘉颖",
      "name_pinyin": "xiejiaying",
      "birthday": "2017-07-06",
      "score1": "81.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "杨子轩",
      "name_pinyin": "yangzixuan",
      "birthday": "2016-10-07",
      "score1": "98.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "詹毓欣妍",
      "name_pinyin": "zhanyuxinyan",
      "birthday": "2017-02-25",
      "score1": "79.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "朱奕然",
      "name_pinyin": "zhuyiran",
      "birthday": "2017-04-07",
      "score1": "73.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "曾国杨",
      "name_pinyin": "zengguoyang",
      "birthday": "2017-04-10",
      "score1": "81.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "胡湘权",
      "name_pinyin": "huxiangquan",
      "birthday": "2017-01-26",
      "score1": "19.0",
      "score2": "8.5",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "秦慕凡",
      "name_pinyin": "qinmufan",
      "birthday": "2017-08-09",
      "score1": "30.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "张城锦",
      "name_pinyin": "zhangchengjin",
      "birthday": "2017-03-26",
      "score1": "47.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "谭旭东",
      "name_pinyin": "tanxudong",
      "birthday": "2017-01-23",
      "score1": "63.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "王苑遐",
      "name_pinyin": "wangyuanxia",
      "birthday": "2017-08-13",
      "score1": "100.0",
      "score2": "71.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "成言蹊",
      "name_pinyin": "chengyanqi",
      "birthday": "2017-04-02",
      "score1": "97.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "谷欣颖",
      "name_pinyin": "guxinying",
      "birthday": "2016-12-21",
      "score1": "68.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "胡舒博",
      "name_pinyin": "hushubo",
      "birthday": "2017-05-11",
      "score1": "19.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "吉湘婷",
      "name_pinyin": "jixiangting",
      "birthday": "2016-11-18",
      "score1": "84.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "秦宇航",
      "name_pinyin": "qinyuhang",
      "birthday": "2017-08-22",
      "score1": "73.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "谢思逸",
      "name_pinyin": "xiesiyi",
      "birthday": "2017-06-06",
      "score1": "73.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "龙鹏宇",
      "name_pinyin": "longpengyu",
      "birthday": "2017-07-07",
      "score1": "25.0",
      "score2": "2.7",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "王晗睿",
      "name_pinyin": "wanghanrui",
      "birthday": "2017-03-05",
      "score1": "19.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "张隽邦",
      "name_pinyin": "zhangjunbang",
      "birthday": "2016-07-02",
      "score1": "63.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "边诗然",
      "name_pinyin": "bianshiran",
      "birthday": "2017-03-04",
      "score1": "5.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "邓云锦",
      "name_pinyin": "dengyunjin",
      "birthday": "2017-05-05",
      "score1": "95.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "冯庭悦",
      "name_pinyin": "fengtingyue",
      "birthday": "2016-05-03",
      "score1": "5.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李欣怡",
      "name_pinyin": "lixinyi",
      "birthday": "2017-01-03",
      "score1": "99.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "潘语垚",
      "name_pinyin": "panyuyao",
      "birthday": "2016-12-05",
      "score1": "94.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "吴若娇",
      "name_pinyin": "wuruojiao",
      "birthday": "2017-01-09",
      "score1": "68.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "张灵悦",
      "name_pinyin": "zhanglingyue",
      "birthday": "2016-12-04",
      "score1": "79.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "傅梵",
      "name_pinyin": "fufan",
      "birthday": "2017-08-21",
      "score1": "30.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "李彦潼",
      "name_pinyin": "liyantong",
      "birthday": "2016-10-18",
      "score1": "81.0",
      "score2": "2.7",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "欧阳致远",
      "name_pinyin": "ouyangzhiyuan",
      "birthday": "2017-08-09",
      "score1": "95.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "唐依一",
      "name_pinyin": "tangyiyi",
      "birthday": "2016-10-14",
      "score1": "75.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "王文逸",
      "name_pinyin": "wangwenyi",
      "birthday": "2017-01-16",
      "score1": "99.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "吴瑾瑜",
      "name_pinyin": "wujinyu",
      "birthday": "2016-10-14",
      "score1": "58.0",
      "score2": "2.7",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "萧梣函",
      "name_pinyin": "xiaochenhan",
      "birthday": "2017-05-09",
      "score1": "92.0",
      "score2": "1.4",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "肖昊奇",
      "name_pinyin": "xiaohaoqi",
      "birthday": "2017-08-30",
      "score1": "95.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "杨峻易",
      "name_pinyin": "yangjunyi",
      "birthday": "2016-09-18",
      "score1": "95.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "姚子扬",
      "name_pinyin": "yaoziyang",
      "birthday": "2017-06-30",
      "score1": "45.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "张沁怡",
      "name_pinyin": "zhangqinyi",
      "birthday": "2017-05-22",
      "score1": "86.0",
      "score2": "1.4",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "周靖怡",
      "name_pinyin": "zhoujingyi",
      "birthday": "2017-06-13",
      "score1": "10.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "戴妤菡",
      "name_pinyin": "daiyuhan",
      "birthday": "2017-03-18",
      "score1": "79.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "胡志豪",
      "name_pinyin": "huzhihao",
      "birthday": "2016-11-09",
      "score1": "5.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "蔡碧霞",
      "name_pinyin": "caibixia",
      "birthday": "2017-06-01",
      "score1": "73.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "林悦",
      "name_pinyin": "linyue",
      "birthday": "2017-03-06",
      "score1": "82.0",
      "score2": "8.5",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "罗明旭",
      "name_pinyin": "luomingxu",
      "birthday": "2016-10-23",
      "score1": "63.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "彭佳瑞",
      "name_pinyin": "pengjiarui",
      "birthday": "2017-01-22",
      "score1": "68.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "砂子塘魅力小学",
      "school_pinyin": "shazitangmeilixiaoxue",
      "name": "夏宇辰",
      "name_pinyin": "xiayuchen",
      "birthday": "2016-09-14",
      "score1": "50.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "冯梓湘",
      "name_pinyin": "fengzixiang",
      "birthday": "2016-10-14",
      "score1": "68.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "李墨寒",
      "name_pinyin": "limohan",
      "birthday": "2017-01-25",
      "score1": "86.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "谭子阳",
      "name_pinyin": "tanziyang",
      "birthday": "2016-11-25",
      "score1": "70.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "陈宣宇",
      "name_pinyin": "chenxuanyu",
      "birthday": "2016-08-29",
      "score1": "70.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "康琰",
      "name_pinyin": "kangyan",
      "birthday": "2017-08-24",
      "score1": "99.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "李简宜",
      "name_pinyin": "lijianyi",
      "birthday": "2017-01-01",
      "score1": "94.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "李林浩",
      "name_pinyin": "lilinhao",
      "birthday": "2016-12-04",
      "score1": "10.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "罗子怡",
      "name_pinyin": "luoziyi",
      "birthday": "2016-09-19",
      "score1": "19.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "彭梓晴",
      "name_pinyin": "pengziqing",
      "birthday": "2016-12-30",
      "score1": "96.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "王浩宇",
      "name_pinyin": "wanghaoyu",
      "birthday": "2017-04-13",
      "score1": "99.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "徐芷菁",
      "name_pinyin": "xuzhijing",
      "birthday": "2017-05-21",
      "score1": "73.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "严禹哲",
      "name_pinyin": "yanyuzhe",
      "birthday": "2016-04-04",
      "score1": "16.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "杨旭琪",
      "name_pinyin": "yangxuqi",
      "birthday": "2016-11-03",
      "score1": "93.0",
      "score2": "4.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "陈安颜",
      "name_pinyin": "chenanyan",
      "birthday": "2017-07-13",
      "score1": "97.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "陈铭",
      "name_pinyin": "chenming",
      "birthday": "2016-09-17",
      "score1": "34.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "郭思齐",
      "name_pinyin": "guosiqi",
      "birthday": "2017-01-06",
      "score1": "99.9",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "倪安雅",
      "name_pinyin": "nianya",
      "birthday": "2017-03-18",
      "score1": "19.0",
      "score2": "71.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "宁紫琪",
      "name_pinyin": "ningziqi",
      "birthday": "2017-01-10",
      "score1": "47.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "宋宇程",
      "name_pinyin": "songyucheng",
      "birthday": "2017-06-14",
      "score1": "88.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "谭靖轩",
      "name_pinyin": "tanjingxuan",
      "birthday": "2016-11-04",
      "score1": "93.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "谢俊欧",
      "name_pinyin": "xiejunou",
      "birthday": "2017-09-08",
      "score1": "81.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "刘皓彤",
      "name_pinyin": "liuhaotong",
      "birthday": "2016-11-27",
      "score1": "79.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "彭梓熙",
      "name_pinyin": "pengzixi",
      "birthday": "2017-07-29",
      "score1": "53.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "莫雨欣",
      "name_pinyin": "moyuxin",
      "birthday": "2017-03-02",
      "score1": "88.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "彭馨睿",
      "name_pinyin": "pengxinrui",
      "birthday": "2016-09-01",
      "score1": "75.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "谢梓涵",
      "name_pinyin": "xiezihan",
      "birthday": "2017-01-07",
      "score1": "63.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "鄢铭惠",
      "name_pinyin": "yanminghui",
      "birthday": "2017-07-10",
      "score1": "86.0",
      "score2": "71.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "刘奕琛",
      "name_pinyin": "liuyichen",
      "birthday": "2017-01-15",
      "score1": "79.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "长塘里思远小学",
      "school_pinyin": "changtanglisiyuanxiaoxue",
      "name": "熊司毅",
      "name_pinyin": "xiongsiyi",
      "birthday": "2017-05-05",
      "score1": "53.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "陈柏奇",
      "name_pinyin": "chenbaiqi",
      "birthday": "2016-12-07",
      "score1": "86.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "彭琪峰",
      "name_pinyin": "pengqifeng",
      "birthday": "2016-12-13",
      "score1": "75.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "邓梓良",
      "name_pinyin": "dengziliang",
      "birthday": "2017-02-11",
      "score1": "27.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "黄琳舒",
      "name_pinyin": "huanglinshu",
      "birthday": "2016-12-20",
      "score1": "96.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "齐嘉晨",
      "name_pinyin": "qijiachen",
      "birthday": "2017-06-19",
      "score1": "95.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "杨惜然",
      "name_pinyin": "yangxiran",
      "birthday": "2017-08-04",
      "score1": "99.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "刘沐依",
      "name_pinyin": "liumuyi",
      "birthday": "2017-06-04",
      "score1": "92.0",
      "score2": "86.7",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "刘一正",
      "name_pinyin": "liuyizheng",
      "birthday": "2016-11-29",
      "score1": "79.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "魏恺",
      "name_pinyin": "weikai",
      "birthday": "2017-04-02",
      "score1": "19.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "徐诗琦",
      "name_pinyin": "xushiqi",
      "birthday": "2017-09-23",
      "score1": "13.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "李晨依",
      "name_pinyin": "lichenyi",
      "birthday": "2016-10-20",
      "score1": "12.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "彭子琪",
      "name_pinyin": "pengziqi",
      "birthday": "2017-02-07",
      "score1": "79.0",
      "score2": "86.7",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "肖潘翊",
      "name_pinyin": "xiaopanyi",
      "birthday": "2017-04-15",
      "score1": "95.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "曾楒琪",
      "name_pinyin": "zengsiqi",
      "birthday": "2016-11-22",
      "score1": "82.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "黄媛琪",
      "name_pinyin": "huangyuanqi",
      "birthday": "2016-11-06",
      "score1": "42.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "雷雅煊",
      "name_pinyin": "leiyaxuan",
      "birthday": "2017-01-03",
      "score1": "47.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "鲁泓毅",
      "name_pinyin": "luhongyi",
      "birthday": "2017-06-27",
      "score1": "13.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "彭哲瀚",
      "name_pinyin": "pengzhehan",
      "birthday": "2017-04-06",
      "score1": "99.0",
      "score2": "71.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "王一凡",
      "name_pinyin": "wangyifan",
      "birthday": "2016-12-30",
      "score1": "19.0",
      "score2": "4.9",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "王语馨",
      "name_pinyin": "wangyuxin",
      "birthday": "2017-08-13",
      "score1": "88.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "杨知臻",
      "name_pinyin": "yangzhizhen",
      "birthday": "2016-12-11",
      "score1": "99.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "赵一菡",
      "name_pinyin": "zhaoyihan",
      "birthday": "2017-06-06",
      "score1": "13.0",
      "score2": "71.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "陈子荀",
      "name_pinyin": "chenzixun",
      "birthday": "2017-03-25",
      "score1": "25.0",
      "score2": "86.7",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "李沐宸",
      "name_pinyin": "limuchen",
      "birthday": "2017-02-16",
      "score1": "99.0",
      "score2": "86.7",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "刘沐佟",
      "name_pinyin": "liumutong",
      "birthday": "2017-09-07",
      "score1": "30.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "颜睿晨",
      "name_pinyin": "yanruichen",
      "birthday": "2017-08-14",
      "score1": "99.0",
      "score2": "4.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "杨翃宇",
      "name_pinyin": "yanghongyu",
      "birthday": "2016-10-09",
      "score1": "99.7",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "易千雅",
      "name_pinyin": "yiqianya",
      "birthday": "2016-11-25",
      "score1": "91.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "冯梓宇",
      "name_pinyin": "fengziyu",
      "birthday": "2016-09-07",
      "score1": "99.7",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "郭斯涵",
      "name_pinyin": "guosihan",
      "birthday": "2017-03-11",
      "score1": "88.0",
      "score2": "71.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "焦隽奚",
      "name_pinyin": "jiaojunxi",
      "birthday": "2017-09-01",
      "score1": "84.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "李亦然",
      "name_pinyin": "liyiran",
      "birthday": "2017-03-06",
      "score1": "82.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "梁徐昶",
      "name_pinyin": "liangxuchang",
      "birthday": "2017-01-27",
      "score1": "47.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "吕嘉兴",
      "name_pinyin": "lvjiaxing",
      "birthday": "2017-02-01",
      "score1": "4.0",
      "score2": "4.9",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "穆浠桐",
      "name_pinyin": "muxitong",
      "birthday": "2017-08-04",
      "score1": "50.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "谭亦诚",
      "name_pinyin": "tanyicheng",
      "birthday": "2017-02-06",
      "score1": "88.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "王彧",
      "name_pinyin": "wangyu",
      "birthday": "2017-02-23",
      "score1": "96.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "陈祥铠",
      "name_pinyin": "chenxiangkai",
      "birthday": "2017-03-05",
      "score1": "88.0",
      "score2": "0.1",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "郭妍熙",
      "name_pinyin": "guoyanxi",
      "birthday": "2017-03-16",
      "score1": "98.0",
      "score2": "71.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "李夏宁",
      "name_pinyin": "lixianing",
      "birthday": "2017-06-09",
      "score1": "70.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "林熙景",
      "name_pinyin": "linxijing",
      "birthday": "2017-02-07",
      "score1": "55.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "刘音慧",
      "name_pinyin": "liuyinhui",
      "birthday": "2016-11-11",
      "score1": "78.0",
      "score2": "4.9",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "谢宇泽",
      "name_pinyin": "xieyuze",
      "birthday": "2017-07-16",
      "score1": "99.0",
      "score2": "86.7",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "易诗涵",
      "name_pinyin": "yishihan",
      "birthday": "2017-08-29",
      "score1": "58.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "张苇伊",
      "name_pinyin": "zhangweiyi",
      "birthday": "2017-04-18",
      "score1": "14.0",
      "score2": "61.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "周珈叶",
      "name_pinyin": "zhoujiaye",
      "birthday": "2017-04-02",
      "score1": "86.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "蔡佳琳",
      "name_pinyin": "caijialin",
      "birthday": "2016-09-28",
      "score1": "90.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "李泽宇",
      "name_pinyin": "lizeyu",
      "birthday": "2016-12-30",
      "score1": "68.0",
      "score2": "86.7",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "肖嘉乐",
      "name_pinyin": "xiaojiale",
      "birthday": "2016-10-19",
      "score1": "84.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "喻萧潼",
      "name_pinyin": "yuxiaotong",
      "birthday": "2017-04-28",
      "score1": "61.0",
      "score2": "61.2",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "张绮妍",
      "name_pinyin": "zhangqiyan",
      "birthday": "2017-04-21",
      "score1": "81.0",
      "score2": "86.7",
      "result": "正常发展"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "陈浩宇",
      "name_pinyin": "chenhaoyu",
      "birthday": "2017-03-09",
      "score1": "14.0",
      "score2": "0.3",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "罗森",
      "name_pinyin": "luosen",
      "birthday": "2016-11-19",
      "score1": "10.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "泰禹第二小学",
      "school_pinyin": "taiyudierxiaoxue",
      "name": "盛开",
      "name_pinyin": "shengkai",
      "birthday": "2017-04-23",
      "score1": "96.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "龙锦霖",
      "name_pinyin": "longjinlin",
      "birthday": "2017-08-20",
      "score1": "50.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "黄雅茜",
      "name_pinyin": "huangyaqian",
      "birthday": "2017-07-27",
      "score1": "99.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险 "
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "殷实",
      "name_pinyin": "yinshi",
      "birthday": "2016-11-14",
      "score1": "98.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险 "
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "周玮骏",
      "name_pinyin": "zhouweijun",
      "birthday": "2017-03-15",
      "score1": "79.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险 "
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "彭诗涵",
      "name_pinyin": "pengshihan",
      "birthday": "2017-03-10",
      "score1": "99.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "王守儒",
      "name_pinyin": "wangshouru",
      "birthday": "2016-12-12",
      "score1": "96.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "熊亦辰",
      "name_pinyin": "xiongyichen",
      "birthday": "2017-01-01",
      "score1": "86.0",
      "score2": "13.6",
      "result": "发展性语言障碍风险 "
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "陈虹润",
      "name_pinyin": "chenhongrun",
      "birthday": "2017-04-28",
      "score1": "99.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "陈洺君",
      "name_pinyin": "chenmingjun",
      "birthday": "2016-10-31",
      "score1": "63.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "吴瑾瑜",
      "name_pinyin": "wujinyu",
      "birthday": "2017-06-06",
      "score1": "25.0",
      "score2": "8.5",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "邹陈沐熹",
      "name_pinyin": "zouchenmuxi",
      "birthday": "2016-11-18",
      "score1": "98.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "胡子宸",
      "name_pinyin": "huzichen",
      "birthday": "2016-10-24",
      "score1": "93.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "陈贝鸣",
      "name_pinyin": "chenbeiming",
      "birthday": "2017-09-14",
      "score1": "30.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "刘枳辛",
      "name_pinyin": "liuzhixin",
      "birthday": "2016-11-04",
      "score1": "75.0",
      "score2": "8.5",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "梁艺馨",
      "name_pinyin": "liangyixin",
      "birthday": "2016-11-16",
      "score1": "75.0",
      "score2": "79.8",
      "result": "发展性语言障碍"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "吴佳静",
      "name_pinyin": "wujiajing",
      "birthday": "2017-05-04",
      "score1": "25.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "长郡雨花外国语学校",
      "school_pinyin": "changjunyuhuawaiguoyuxuexiao",
      "name": "张天宇",
      "name_pinyin": "zhangtianyu",
      "birthday": "2016-11-28",
      "score1": "79.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "程梓熙",
      "name_pinyin": "chengzixi",
      "birthday": "2016-10-09",
      "score1": "81.0",
      "score2": "13.6",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "何嫣雅",
      "name_pinyin": "heyanya",
      "birthday": "2017-08-16",
      "score1": "16.0",
      "score2": "1.4",
      "result": "发展性语言障碍"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "刘佳琦",
      "name_pinyin": "liujiaqi",
      "birthday": "2016-11-07",
      "score1": "8.0",
      "score2": "0.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "刘俊豪",
      "name_pinyin": "liujunhao",
      "birthday": "2016-05-30",
      "score1": "75.0",
      "score2": "4.9",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "刘婷娜",
      "name_pinyin": "liutingna",
      "birthday": "2017-01-10",
      "score1": "14.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "舒子涵",
      "name_pinyin": "shuzihan",
      "birthday": "2016-09-16",
      "score1": "63.0",
      "score2": "61.2",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "粟伊航",
      "name_pinyin": "suyihang",
      "birthday": "2017-04-13",
      "score1": "45.0",
      "score2": "61.2",
      "result": "发展性语言障碍"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "孙依琳",
      "name_pinyin": "sunyilin",
      "birthday": "2017-01-04",
      "score1": "55.0",
      "score2": "2.7",
      "result": "发展性语言障碍"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "唐琨庚",
      "name_pinyin": "tangkungeng",
      "birthday": "2017-01-22",
      "score1": "27.0",
      "score2": "79.8",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "黎妮",
      "name_pinyin": "lini",
      "birthday": "2016-12-22",
      "score1": "19.0",
      "score2": "8.5",
      "result": "发展性语言障碍风险"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "孙旭阳",
      "name_pinyin": "sunxuyang",
      "birthday": "2016-11-30",
      "score1": "42.0",
      "score2": "71.2",
      "result": "正常发展"
    },
    {
      "school": "育新第三小学",
      "school_pinyin": "yuxindisanxiaoxue",
      "name": "徐婉茹",
      "name_pinyin": "xuwanru",
      "birthday": "2016-11-30",
      "score1": "12.0",
      "score2": "13.6",
      "result": "发展性语言障碍"
    }
  ]
}
    if(typeof module.exports!== 'undefined') {
      module.exports = data;
    } else {
      exports.default = data;
    }
    