<template>
  <div id="app">
    <van-notice-bar
      left-icon="volume-o"
      text="尊敬的家长，请于2025年2月4日前完成查询，过时系统将关闭，您可选择下载报告以永久保存，谢谢！"
      v-if="showNotice"
    />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // 跑生成pdf脚本时不用展示
      showNotice: !window.studentInfo, // 自动化时注入的学生数据
    };
  },
};
</script>

<style lang="less">
body {
  font-family: "PingFang SC Regular", "方正兰亭黑简", BlinkMacSystemFont,
    "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", miui,
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}
</style>
