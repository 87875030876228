<template>
  <div class="pdf">
    <template v-if="preview">
      <vue-pdf-embed :source="href" style="width: 100vw" />
      <a class="download affix" :href="href" target="_blank"> 下载 </a>
    </template>

    <template v-else>
      <van-button class="preview" type="primary" block @click="preview = true">
        预览
      </van-button>
      <a class="download" :href="href" target="_blank"> 下载 </a>
    </template>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  name: "Pdf2",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      preview: false,
    };
  },
  computed: {
    link() {
      return this.$route.query.link;
    },
    filename() {
      return this.link;
    },
    href() {
      return "/pdfs/" + this.link + ".pdf";
    },
  },
  methods: {
    down() {
      function downloadPdf(pdfDataUri, filename) {
        // 创建隐藏的可下载链接
        var link = document.createElement("a");
        link.href = pdfDataUri;
        link.download = filename;
        link.style.display = "none";

        // 触发点击事件以下载文件
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // 假设fetch或XHR请求获取到了PDF数据
      fetch(this.href)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建Blob URL
          const blobUrl = window.URL.createObjectURL(blob);
          // 调用下载函数
          downloadPdf(blobUrl, this.filename);
        });
    },
  },
};
</script>

<style scoped lang="less">
.pdf {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .van-button {
    margin-bottom: 20px;
    &.preview {
      margin-top: 30vh;
    }
  }
  .download {
    display: flex;
    justify-content: center;
    align-items: center;
    // position: fixed;
    bottom: 0;
    height: 44px;
    border: 1px solid #eee;
    width: 100%;
    color: #333;
    &.affix {
      position: fixed;
      bottom: 0;
      width: 100%;
      color: #fff;
      background-color: #07c160;
      border: 1px solid #07c160;
    }
  }
}
</style>
