<template>
  <div class="student">
    <V2 v-if="studentInfo" :studentInfo="studentInfo" />
    <Login2 v-else @submit="submit" />
  </div>
</template>

<script>
import Login2 from "@/components/Login2.vue";
import V2 from "@/components/pdfTempaltes/V2.vue";
import all from "@/utils/constants/students";

export default {
  name: "Student2",
  components: { Login2, V2 },
  data() {
    return {
      studentInfo: window.studentInfo || null, // 自动化时注入的学生数据
      dev: this.$route.query.dev || false, // 是否是本地预览
    };
  },
  beforeCreate() {
    return;
    // TODO:模拟数据
    window.studentInfo = {
      school: "博长幼儿园",
      school_pinyin: "bochangyoueryuan",
      name: "郭芷睿",
      name_pinyin: "guozhirui",
      birthday: "2018/1/16",
      score1: "18",
      score2: "5.4",
      result: "发展性语言障碍风险",
    };
  },
  mounted() {},
  methods: {
    async submit(values) {
      const { school, name, birthday } = values;
      // 查找本地文件
      const studentInfo = all.data.find(
        (i) => i.school === school && i.name === name && i.birthday === birthday
      );
      if (studentInfo) {
        // 本地预览
        if (this.dev) {
          this.studentInfo = studentInfo;
        } else {
          const { school_pinyin, name_pinyin } = studentInfo;
          // 用户预览
          const link = `${school_pinyin}-${name_pinyin}-${birthday}`;
          this.$router.push({
            name: "pdf2",
            query: { link },
          });
        }
      } else {
        this.$notify({
          type: "warning",
          message: "请输入正确的学生信息",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.student {
}
</style>
